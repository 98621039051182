<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2024/04/hero-iconic-nz-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,0.65), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.25) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2024/04/iconic-nz-itineraries-stacked.png" alt="Iconic New Zealand Itineraries" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <small>Milford Sound, Fiordland - Photo: Will Patino</small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <v-layout row wrap>
                        <v-flex xs12 md12 py-0 my-0>
                          <span
                        class="headline font-weight-medium kiwiGreen d-block mb-3"
                      >Embark on unforgettable journeys through New Zealand's breathtaking landscapes, vibrant culture, and iconic landmarks with our quintesential NZ itineraries.</span>
                          <p>Experience the essence of Aotearoa through these quintessential New Zealand itineraries. Traverse the rugged beauty of Fiordland, savor the vineyards of Marlborough, and explore the cultural tapestry of Rotorua. Whether seeking adrenaline-pumping adventures or serene moments of reflection, our curated journeys ensure you uncover the true spirit of this enchanting land.</p>
                          <p>Combine, extend, shorten, customise or simply book them as they are, these packages act as both the ultimate holiday itineraries to New Zealand, and a building block for smaller or larger trips.</p>
                        </v-flex>
                        <!--<v-flex xs12 md2 py-0 my-0 text-xs-center>
                          <a target="_blank" href="https://issuu.com/holidayexperts/docs/warm_up_nzd?fr=sZTYwYjU3MTE2OTE"><img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/03/warm-up-in-australia-cover-sml.jpg" alt="Warm Up in Australia eBrochure Cover" class="campaign-sticker kirra-early-bird" width="124" height="174" /></a>
                          <small class="kiwiGreen d-block mt-2" style="line-height:1.2;"><a target="_blank" href="https://issuu.com/holidayexperts/docs/warm_up_nzd?fr=sZTYwYjU3MTE2OTE"><b>View eBrochure</b></a></small>
                        </v-flex>-->
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (AUD)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-center>
                            <span class="caption grey--text">Agent Resources</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                    <b>Call ANZCRO to Book</b><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right text-md-center>
                            <v-tooltip top v-if="deal.sm_tile">
                              <template v-slot:activator="{ on }">
                                <v-btn large v-on="on" icon class="card-with-border elevation-0 mr-1 px-0" color="white" :href="deal.sm_tile" target="_blank"><v-icon :color="kiwiGreen" size="22" class="">image</v-icon></v-btn>
                              </template>
                              <span>View Social Media Tile</span>
                            </v-tooltip>
                            <v-tooltip top v-if="deal.itinerary">
                              <template v-slot:activator="{ on }">
                                <v-btn large v-on="on" icon class="card-with-border elevation-0 mx-0 px-0" :color="kiwiGreen" :href="deal.itinerary" target="_blank"><v-icon color="white" size="22" class="">description</v-icon></v-btn>
                              </template>
                              <span>View Flyer</span>
                            </v-tooltip>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
        
        {
          title : 'Self-Drive Itineraries',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/136x136-Thermal.jpg',
              name : '8 Day Thermal Explorer',
              price : '1835',
              complement : 'per person (twin share)',
              code : 'ANZ24NITE',
              location : 'Auckland (AKL)',
              valid: '01 May 2024 - 31 Mar 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/8-Day-North-Island-Thermal-Explorer-AUD.pdf',
              sm_tile : null,
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/136x136-North-Island.jpg',
              name : '10 Day New Zealand\'s North Island',
              price : '2299',
              complement : 'per person (twin share)',
              code : 'ANZ24NZNI',
              location : 'Auckland (AKL)',
              valid: '01 May 2024 - 31 Mar 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/10-Day-New-Zealands-North-Island-AUD.pdf',
              sm_tile : null,
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/Hobbiton-Wine-Food-Discovery-Tour-Thumbnail.png',
              name : '11 Days Hobbiton to Coast: Wine & Food Discovery Tour',
              price : '3449',
              complement : 'per person (twin share)',
              code : 'ANZ10NECD',
              location : 'Auckland (AKL)',
              valid: '01 Sep 2024 – 28 Feb 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/11-Days-Hobbiton-to-Coast-AUD.pdf',
              sm_tile : null,
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/136x136-Grand-Rail.jpg',
              name : '12 Day Grand Rail Explorer',
              price : '2995',
              complement : 'per person (twin share)',
              code : 'ANZ2412DGRE',
              location : 'Queenstown (ZQN)',
              valid: '16 Sep 2024 - 09 Dec 2024 (select departures)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/12-Day-Grand-Rail-Explorer-AUD.pdf',
              sm_tile : null,
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/136x136-South-Island.jpg',
              name : '13 Day New Zealand\'s South Island',
              price : '3539',
              complement : 'per person (twin share)',
              code : 'ANZ13DNZSI',
              location : 'Christchurch (CHC)',
              valid: '01 May 2024 - 17 Mar 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/13-Day-New-Zealands-South-Island-AUD.pdf',
              sm_tile : null,
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/136x136-Both-Islands.jpg',
              name : '25 Day New Zealand\'s North & South',
              price : '7239',
              complement : 'per person (twin share)',
              code : 'ANZ25DNS',
              location : 'Christchurch (CHC)',
              valid: '01 Oct 2024 - 08 Mar 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/25-Day-New-Zealands-North-South-AUD.pdf',
              sm_tile : null,
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/Deal-Thumbnail-Image.jpg',
              name : '26 Days Natural New Zealand',
              price : '14555',
              complement : 'per person (twin share)',
              code : 'ANZ2426DNNZ',
              location : 'Auckland (AKL)',
              valid: '01 Oct 2024 – 06 Mar 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/26-Day-Natural-New-Zealand-AUD.pdf',
              sm_tile : null,
              call : false
            },
          ]
        },
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  