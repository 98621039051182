<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2022/01/hero-deals-australia-bucket-list-aud-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.2) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <!--<h1 class="white--text display-1 font-weight-bold mb-3">
                            Hot Offers on<br/>Holidays at Home
                          </h1>-->
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/01/deal-iconic-bucket-list-2022-stacked.png" alt="Australia Iconic Bucket List Adventures 2022" class="campaign-title stacked" width="480" height="300" />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Bungle Bungle Range, WA</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 py-0 my-0>
                <v-card tile class="pa-0 elevation-0 deals-content">
                  <v-card-text class="pa-5">
                    <span
                      class="headline font-weight-medium kiwiGreen d-block mb-3"
                    >Now is the time to tick an iconic Aussie experience off your bucket list.</span>
                    <p>From the heart of the Outback to the spirit of the Top End and everywhere in between, find the perfect holiday package to take advantage of our spectacularly diverse country.</p>
                    <p>Life is short, book your bucket list holiday today!</p>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                <v-card tile dark class="pa-0 ma-0 elevation-0">
                  <v-card-text class="px-3 py-2">
                    <span
                      class="headline font-weight-medium d-block"
                    >{{section.title}}</span>
                  </v-card-text>
                </v-card>
                <v-card tile class="px-3 py-3 elevation-0">
                  <v-layout row wrap ma-0>
                    <v-flex xs12 pa-0 hidden-sm-and-down>
                      <v-layout row wrap my-0>
                        <v-flex xs12 md7 lg8>
                          <span class="caption grey--text">Package Deal</span>
                        </v-flex>
                        <v-flex xs12 md3 lg2 text-md-right>
                          <span class="caption grey--text">From Price (AUD)</span>
                        </v-flex>
                        <v-flex xs12 md2 lg2 text-md-right>
                          <span class="caption grey--text">Inclusions & Terms</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                      <v-divider></v-divider>
                      <v-layout row wrap align-center my-3>
                        <v-flex xs12 md7 lg8>
                          <v-layout row align-center>
                            <v-flex shrink hidden-md-and-down>
                              <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                            </v-flex>
                            <v-flex>
                              <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                              <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                              <span class="d-block package-data">
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Start location</span>
                                  </v-tooltip>
                                </v-chip>
                                <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                  <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Package Code</span>
                                  </v-tooltip>
                                </v-chip>
                                <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                  <b>Call ANZCRO to Book</b><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                                </v-chip>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm6 md3 lg2 text-md-right>
                          <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                          <span class="caption d-block grey--text">{{deal.complement}}</span>
                        </v-flex>
                        <v-flex xs12 sm6 md2 lg2 text-sm-right>
                          <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
      </v-flex>
    </v-layout>


  <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    copied: false,
    copiedText: '',
    sections : [
      {
        title : 'QLD Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deal-thumbnail-australia-bucket-list-qld-1.jpg',
            name : '6 Days Whitsundays Stay & Sail',
            price : '1439',
            complement : 'per person (twin share)',
            code : 'ANZAU226DWSS',
            location : 'Airlie Beach (AIR)',
            valid: '01 May 2022 - 30 Nov 2022 (select dates)',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/Queensland-Whitsundays-Bucket-List-AUD-flyer.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deal-thumbnail-australia-bucket-list-qld-3.jpg',
            name : '6 Days Reef & Rainforest Retreat',
            price : '1599',
            complement : 'per person (twin share)',
            code : 'ANZAU226DRRR',
            location : 'Port Douglas (PTD)',
            valid: '01 May 2022 - 31 Oct 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/Queensland-Whitsundays-Bucket-List-2-AUD-flyer.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deal-thumbnail-australia-bucket-list-qld-2.jpg',
            name : '5 Days Hayman Island Indulgence',
            price : '2169',
            complement : 'per person (twin share)',
            code : 'ANZ225DHII',
            location : 'Hayman  (HIS)',
            valid: '01 Jul 2022 - 30 Nov 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/Queensland-Whitsundays-Bucket-List-AUD-flyer.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deal-thumbnail-australia-bucket-list-qld-4.jpg',
            name : '5 Days Mount Mulligan Luxury Lodge',
            price : '2899',
            complement : 'per person (twin share)',
            code : 'ANZAU224NMM',
            location : 'Mount Mulligan',
            valid: '01 Mar 2022 - 08 Apr 2022 & 10 Oct 2022 - 16 Dec 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/Queensland-Whitsundays-Bucket-List-2-AUD-flyer.pdf',
            call : false
          },
        ]
      },
      {
        title : 'NT Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deal-thumbnail-australia-bucket-list-nt-02.jpg',
            name : '4 Days Iconic Red Centre',
            price : '1399',
            complement : 'per person (twin share)',
            code : ' ANZD224DIRC',
            location : 'Ayers Rock/Yulara (AYQ)',
            valid: '01 Jul 2022 - 25 Oct 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/Northern-Territory-Bucket-List-AUD-flyer.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deal-thumbnail-australia-bucket-list-nt-01.jpg',
            name : '6 Days Top End Explorer',
            price : '1899',
            complement : 'per person (twin share)',
            code : 'ANZD6DTEE',
            location : 'Darwin (DRW)',
            valid: 'May-Sep 2022 (select Mondays & Fridays)',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/Northern-Territory-Bucket-List-AUD-flyer.pdf',
            call : false
          },
        ]
      },
      {
        title : 'SA Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/01/deal-thumbnail-australia-bucket-list-sa-2.jpg',
            name : '4 Days Kangaroo Island Farmstay',
            price : '2399',
            complement : 'per person (twin share)',
            code : 'ANZ224DKIF',
            location : 'Kangaroo Island (KAN)',
            valid: '01 May 2022 - 31 Oct 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/01/South-Australia-Bucket-List-AUD-flyer-1.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/01/deal-thumbnail-australia-bucket-list-sa-1.jpg',
            name : '6 Days Flinders Ranges and Outback',
            price : '3199',
            complement : 'per person (twin share)',
            code : 'ANZ226DFRO',
            location : 'Adelaide (ADL)',
            valid: '06 May 2022 - 10 Sep 2022 (select dates)',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/01/South-Australia-Bucket-List-AUD-flyer-1.pdf',
            call : false
          },
        ]
      },
      {
        title : 'WA Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/01/deal-thumbnail-australia-bucket-list-wa-1.jpg',
            name : '5 Days Bungle Bungles Safari',
            price : '1795',
            complement : 'per person (twin share)',
            code : 'ANZ225DBBS',
            location : 'Broome (BME)',
            valid: 'May-Sep 2022 (select Tuesdays)',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/01/Western-Australia-Bucket-List-AUD-flyer.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/01/deal-thumbnail-australia-bucket-list-wa-2.jpg',
            name : '4 Days Ningaloo Reef Adventure',
            price : '4199',
            complement : 'per person (twin share)',
            code : 'ANZ224DNRA',
            location : 'Exmouth (EXM)',
            valid: '01 Aug 2022 - 30 Sep 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/01/Western-Australia-Bucket-List-AUD-flyer.pdf',
            call : false
          },
        ]
      },
      {
        title : 'TAS Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deal-thumbnail-australia-bucket-list-tas-1.jpg',
            name : '9 Days Icons of Tasmania Self-Drive',
            price : '1999',
            complement : 'per person (twin share)',
            code : 'ANZAU229DIOT',
            location : 'Hobart (HBA)',
            valid: '01 Oct 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/Tasmania-Bucket-List-AUD-Flyer.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deal-thumbnail-australia-bucket-list-tas-2.jpg',
            name : '5 Days Freycinet Experience Walk',
            price : '3119',
            complement : 'per person (twin share)',
            code : 'AUPB225DFEW',
            location : 'Hobart (HBA)',
            valid: '01 Oct 2022 - 31 Mar 2023 (select dates)',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/Tasmania-Bucket-List-AUD-Flyer.pdf',
            call : false
          },
        ]
      },
    ]
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    copyCode(event) {
      if(event){
        let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
        window.getSelection().selectAllChildren(copyCode);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyCode.innerHTML);
        this.copiedText = 'Package code copied to clipboard';
        this.copied = true;
      }
    },
    copyLocation(event) {
      if(event){
        let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
        window.getSelection().selectAllChildren(copyLocation);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyLocation.innerHTML);
        this.copiedText = 'Package start location copied to clipboard';
        this.copied = true;
      }
    }
  },
};
</script>

<style>
.gradient-bar{
  width: 100%;
  height: 9px;
  background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
  background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
}

.deals-content{
  margin-top: -60px;
}

.package-data .v-chip{
  color:#9e9e9e;
  margin-left: 0;
}

.package-data .v-chip .v-chip__content{
  padding-right: 6px;
}

.deal-thumbnail{
  width: 88px;
  height: 88px;
}
</style>
