<template>
  <v-container pa-0 fluid>
    <v-layout row class="hero-waves">
      <v-flex>
        <v-container fill-height>
          <v-layout dark row wrap fill-height align-center pt-5>
            <v-flex xs12 text-xs-center text-md-left class="hero-title">
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2020/01/anzcro-connect-inverted.svg"
                width="220"
                class="my-2 d-inline-block"
              ></v-img>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-layout row class="registration-main">
      <v-flex>
        <v-card flat tile>
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 md8 pt-0 mb-4 mt-3>
                <v-card class="pa-0 elevation-0 dash-rounded">
                  <v-card-text class="pa-4">
                    <span
                      class="headline font-weight-medium kiwiGreen d-block mb-3"
                    >Register your Travel Agency</span>
                    <span class="subheading d-block">If you have not worked with us before, please register your agency to gain access to the largest portfolio of Australian, New Zealand and South Pacific travel.</span>
                  </v-card-text>
                  <v-divider class="mx-3 my-3"></v-divider>
                  <v-form v-model="valid">
                    <v-stepper v-model="registerStepper" vertical class="elevation-0">
                      <v-stepper-step :complete="registerStepper > 1" step="1" editable>
                        <span class="subheading d-block mb-1 font-weight-medium">Agency Details</span>
                        <small class="font-weight-medium">Enter your agency details below.</small>
                      </v-stepper-step>

                      <v-stepper-content step="1">
                        <v-form ref="detailForm">
                          <v-card flat color="transparent" class="mb-4">
                            <v-layout row wrap>
                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  tabindex="1"
                                  v-model="agencyName"
                                  :rules="agencyNameRules"
                                  box
                                  label="Agency Name (required)"
                                  required
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  tabindex="2"
                                  v-model="agencyAbnAcn"
                                  :rules="agencyAbnAcnRules"
                                  box
                                  label="ABN / ACN (required)"
                                  required
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 pb-1>
                                <v-select
                                  tabindex="3"
                                  v-model="agencyAffiliateProgram"
                                  :items="affiliatePrograms"
                                  box
                                  label="Affiliate Program"
                                ></v-select>
                              </v-flex>

                              <v-flex xs12 mt-3>
                                <span class="subheading font-weight-medium">Contact</span>
                              </v-flex>

                              <v-flex xs12 pb-1>
                                <v-text-field
                                  tabindex="4"
                                  v-model="agencyEmail"
                                  :rules="agencyEmailRules"
                                  box
                                  label="Agency Email (required)"
                                  required
                                  type="email"
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  tabindex="5"
                                  v-model="agencyPhone"
                                  :rules="agencyPhoneRules"
                                  box
                                  label="Phone (required)"
                                  required
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  tabindex="6"
                                  v-model="agencyFax"
                                  box
                                  label="Fax (optional)"
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 mt-3>
                                <span class="subheading font-weight-medium">Address</span>
                              </v-flex>

                              <v-flex xs12 pb-1>
                                <v-text-field
                                  tabindex="7"
                                  v-model="agencyStreetAddress"
                                  :rules="agencyStreetAddressRules"
                                  box
                                  label="Street Address (required)"
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  tabindex="8"
                                  v-model="agencyCitySuburb"
                                  :rules="agencyCitySuburbRules"
                                  box
                                  label="City / Suburb (required)"
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  tabindex="9"
                                  v-model="agencyState"
                                  :rules="stateProvinceRules"
                                  box
                                  label="State / Region (required)"
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  tabindex="10"
                                  v-model="agencyPostcode"
                                  :rules="agencyPostcodeRules"
                                  box
                                  label="Postcode (required)"
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-autocomplete
                                  tabindex="11"
                                  v-model="agencyCountry"
                                  :items="countries"
                                  item-text="name"
                                  item-value="name"
                                  :rules="[v => !!v || 'Country is required']"
                                  box
                                  clearable
                                  label="Country (required)"
                                ></v-autocomplete>
                              </v-flex>
                            </v-layout>

                            <v-layout row wrap>
                              <v-flex xs12>
                                <span class="subheading font-weight-medium">Postal Address</span>
                              </v-flex>

                              <v-flex xs12 pb-1>
                                <v-checkbox
                                  v-model="agencyPostalAddressOption"
                                  label="Same as above"
                                  :color="kiwiGreen"
                                ></v-checkbox>
                              </v-flex>

                              <v-flex xs12 pb-1 v-if="!agencyPostalAddressOption">
                                <v-textarea
                                  box
                                  v-model="agencyPostalAddress"
                                  :rules="agencyPostalAddressRules"
                                  label="Postal Address"
                                  required
                                  :color="kiwiGreen"
                                ></v-textarea>
                              </v-flex>

                              <v-flex xs12 pt-0>
                                <v-checkbox
                                  v-model="requestAccount"
                                  label="I would like to be the agency administrator."
                                  :color="kiwiGreen"
                                ></v-checkbox>
                              </v-flex>
                            </v-layout>

                          </v-card>
                          <v-btn
                            v-if="requestAccount"
                            large
                            color="primary"
                            @click="gotoAccountManager()"
                            class="mx-0 elevation-0 card-with-border"
                          >Continue</v-btn>
                          <v-btn
                            v-if="!requestAccount"
                            large
                            color="primary"
                            :loading="loading"
                            @click="submitWithoutAccount()"
                            class="ml-0 elevation-0 card-with-border"
                          >Register Agency</v-btn>
                        </v-form>
                      </v-stepper-content>

                      <v-stepper-step step="2" v-if="requestAccount">
                        <span class="subheading d-block mb-1 font-weight-bold">Your Details</span>
                        <small class="font-weight-medium">Agency Administrator</small>
                      </v-stepper-step>

                      <v-stepper-content step="2" v-if="requestAccount">
                        <v-form ref="managerForm" v-if="registerStepper==2">
                          <v-card flat color="transparent" class="mb-4">
                            <v-layout row wrap>
                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  v-model="firstname"
                                  :rules="firstNameRules"
                                  box
                                  label="First Name"
                                  required
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  v-model="lastname"
                                  :rules="lastNameRules"
                                  box
                                  label="Last Name"
                                  required
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  v-model="email"
                                  :rules="emailRules"
                                  box
                                  label="Email"
                                  required
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  v-model="confirmEmail"
                                  :rules="emailConfirmationRules"
                                  box
                                  label="Confirm Email"
                                  required
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 pt-0>
                                <v-checkbox v-model="agreed" :rules="[v => v || 'You haven\'t agreed to the Terms of Use']" :color="kiwiGreen">
                                  <template v-slot:label>
                                    Tick here to indicate that you have read and agree to the&nbsp;
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <a
                                          target="_blank"
                                          href="/website-terms/"
                                          @click.stop
                                          v-on="on"
                                        >
                                          Terms of Use
                                        </a>
                                      </template>
                                      View Terms of Use
                                    </v-tooltip>
                                  </template>
                                </v-checkbox>
                              </v-flex>

                            </v-layout>
                          </v-card>
                          <v-btn
                            large
                            color="primary"
                            :loading="loading"
                            @click="submitWithAccount()"
                            class="ml-0 elevation-0 card-with-border"
                          >Register Agency</v-btn>
                        </v-form>
                      </v-stepper-content>
                    </v-stepper>
                  </v-form>
                </v-card>
              </v-flex>
              <v-flex xs12 md4 mt-3>
                <v-card flat class="pa-0 elevation-0 dash-rounded mb-4 card-with-border">
                  <v-card-text class="pa-4">
                    <span
                      class="headline font-weight-medium kiwiGreen d-block mb-3"
                    >Access ANZCRO Connect</span>
                    <span
                      class="subheading d-block"
                    >If your agency is already setup with ANZCRO but you don't have an ANZCRO Connect login yet, click below to get an account. </span>
                  </v-card-text>
                  <v-divider class="mx-3 mt-2"></v-divider>
                  <v-card-text class="pa-4">
                    <v-btn
                      dark
                      large
                      :color="headerGrey"
                      to="/register/"
                      class="ma-0 elevation-0 card-with-border px-4"
                    >Register for ANZCRO Connect</v-btn>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { agencyRegistrationMixin } from './agencyregistration.mixin'
export default {
  mixins: [agencyRegistrationMixin],
  data: () => ({
    agencyAffiliateProgram: 'Independent (no affiliation)',
    affiliatePrograms: [
      'Archer Travel Services',
      'ATAC',
      'Corporate Traveller',
      'Corporate Travel Partner: CTP',
      'Express Travel Group',
      'FCM',
      'Flight Centre',
      'helloworld',
      'helloworld Associate',
      'helloworld Business Travel',
      'Independent (no affiliation)',
      'Independent Travel Group (part of ETG)',
      'Intrepid Travel',
      'itravel',
      'Magellan Travel Group',
      'My Travel Group',
      'Travel Associates',
      'Travellers Choice',
      'TravelManagers',
      'Travel Partners',
    ],
    agencyAbnAcnRules: [v => !!v || 'ABN / ACN Number is required'],
    agencyCountry: 'Australia',
  }),
};
</script>