<template>
  <v-container pa-0 fluid>
    <v-layout row class="hero-waves">
      <v-flex>
        <v-container fill-height>
          <v-layout dark row wrap fill-height align-center pt-5>
            <v-flex xs12 text-xs-center text-md-left class="hero-title">
              <h1 class="white--text display-1 font-weight-bold mb-3">Contact Us</h1>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-layout row class="registration-main">
      <v-flex>
        <v-card flat tile class="pb-5">
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 md8 pt-0 mb-4 mt-3>
                <v-card class="pa-0 elevation-0 dash-rounded">
                  <v-card-text class="pa-4">
                    <span
                      class="headline font-weight-medium kiwiGreen d-block mb-3"
                    >Let our friendly staff help you</span>
                    <span
                      class="subheading d-block"
                    >If you have any further questions regarding tours or the travel information on our website, please feel free to contact us either by using the form below, or by calling one of our friendly team members on the telephone numbers listed.</span>
                    <v-divider class="my-4"></v-divider>
                    <v-form ref="contactForm" v-model="valid">
                      <v-layout row wrap my-0>
                        <v-flex xs12 sm6 pb-1>
                          <v-text-field
                            tabindex="1"
                            v-model="firstname"
                            :rules="firstNameRules"
                            box
                            label="First Name (required)"
                            required
                            :color="kiwiGreen"
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 pb-1>
                          <v-text-field
                            tabindex="2"
                            v-model="lastname"
                            :rules="lastNameRules"
                            box
                            label="Last Name (required)"
                            required
                            :color="kiwiGreen"
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 pb-1>
                          <v-text-field
                            tabindex="3"
                            v-model="email"
                            :rules="emailRules"
                            box
                            label="Email (required)"
                            required
                            type="email"
                            :color="kiwiGreen"
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 pb-1>
                          <v-text-field
                            tabindex="4"
                            v-model="phone"
                            box
                            label="Phone"
                            :color="kiwiGreen"
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 pb-1>
                          <v-textarea
                            tabindex="5"
                            v-model="message"
                            :rules="messageRules"
                            box
                            label="Message (required)"
                            required
                            :color="kiwiGreen"
                          ></v-textarea>
                        </v-flex>
                      </v-layout>
                      <v-btn
                        large
                        color="primary"
                        :loading="loading"
                        @click="submit()"
                        class="ml-0 elevation-0 card-with-border"
                      >Send</v-btn>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md4 mt-3>
                <v-card flat class="pa-0 elevation-0 dash-rounded mb-4 card-with-border">
                  <v-card-text class="pa-4">
                    <v-layout row>
                      <v-flex>
                        <span class="title font-weight-medium headerGrey d-block mb-2">Reservations</span>
                      </v-flex>
                    </v-layout>
                    <v-divider class="mb-2"></v-divider>
                    <v-layout row>
                      <v-flex shrink>
                        <v-icon>phone</v-icon>
                      </v-flex>
                      <v-flex text-xs-right>
                        <span class="subheading font-weight-medium">1300 366 966</span>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
                <v-card flat class="pa-0 elevation-0 dash-rounded mb-4 card-with-border">
                  <v-card-text class="pa-4">
                    <v-layout row>
                      <v-flex>
                        <span class="title font-weight-medium headerGrey d-block mb-2">Email</span>
                      </v-flex>
                    </v-layout>
                    <v-divider class="mb-2"></v-divider>
                    <v-layout row>
                      <v-flex shrink>Reservations</v-flex>
                      <v-flex text-xs-right>
                        <a href="mailto:anzcro@anzcro.com.au">anzcro@anzcro.com.au</a>
                      </v-flex>
                    </v-layout>
                    <v-divider class="my-2"></v-divider>
                    <v-layout row>
                      <v-flex shrink>Group Travel</v-flex>
                      <v-flex text-xs-right>
                        <a href="mailto:groups@anzcro.com.au">groups@anzcro.com.au</a>
                      </v-flex>
                    </v-layout>
                    <v-divider class="my-2"></v-divider>
                    <v-layout row>
                      <v-flex shrink>Suppliers</v-flex>
                      <v-flex text-xs-right>
                        <a href="mailto:product@anzcro.com.au">product@anzcro.com.au</a>
                      </v-flex>
                    </v-layout>
                    <v-divider class="my-2"></v-divider>
                    <v-layout row>
                      <v-flex shrink>Marketing</v-flex>
                      <v-flex text-xs-right>
                        <a href="mailto:marketing@anzcro.com.au">marketing@anzcro.com.au</a>
                      </v-flex>
                    </v-layout>
                    <v-divider class="my-2"></v-divider>
                    <v-layout row>
                      <v-flex shrink>Finance</v-flex>
                      <v-flex text-xs-right>
                        <a href="mailto:accounts@anzcro.com.au">accounts@anzcro.com.au</a>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>

                <v-card flat class="pa-0 elevation-0 dash-rounded mb-4 card-with-border">
                  <v-card-text class="pa-4">
                    <v-layout row>
                      <v-flex>
                        <span class="title font-weight-medium headerGrey d-block mb-2">Head Office</span>
                      </v-flex>
                    </v-layout>
                    <v-divider class="mb-2"></v-divider>
                    <v-layout row wrap>
                      <v-flex xs12 py-2>
                        <span>
                          Level 1, 6 Short Street
                          <br />Southport, QLD 4215
                          <br />Australia
                        </span>
                      </v-flex>
                      <v-flex xs12 py-2>
                        <span>ABN 16073214490</span>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>

                <v-card flat class="pa-0 elevation-0 dash-rounded mb-4 card-with-border">
                  <v-card-text class="pa-4">
                    <v-layout row>
                      <v-flex>
                        <span class="title font-weight-medium headerGrey d-block mb-2">Emergency Support</span>
                      </v-flex>
                    </v-layout>
                    <v-divider class="mb-2"></v-divider>
                    <v-layout row wrap>
                      <v-flex xs12 py-2>
                        <small>
                          For routine assistance, contact your travel agent.<br/>In case of emergencies or urgent support after hours,<br/>call ANZCRO's 24/7 Emergency Support line.
                        </small>
                      </v-flex>
                      <v-flex xs12 py-2>
                        <v-expansion-panel class="elevation-0">
                          <v-expansion-panel-content id="emergency_support">
                            <template v-slot:header>
                              <div>
                                <span>Emergency Support Line</span>
                              </div>
                            </template>
                            <v-card class="elevation-0 px-0 py-2">
                              <v-layout row>
                                <v-flex shrink>
                                  <v-icon>phone</v-icon>
                                </v-flex>
                                <v-flex text-xs-right>
                                  <span class="subheading font-weight-medium">+61 7 5556 5500</span>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>

              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { makeZapierService } from '../../services/zapier.service';
export default {
  data: () => ({
    valid: false,
    firstname: '',
    firstNameRules: [v => !!v || 'First name is required'],
    lastname: '',
    lastNameRules: [v => !!v || 'Last name is required'],
    email: '',
    emailRules: [v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid'],
    phone: '',
    message: '',
    messageRules: [v => !!v || 'Message is required'],
    loading: false
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    async submit() {
      this.loading = true
      if (!this.$refs.contactForm.validate()) {
        this.loading = false
        return
      }
      await makeZapierService(this.$region).sendContactUsForm({
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        phone: this.phone,
        message: this.message
      })
      this.$router.push({ name: 'thankyouContact'});
    }
  },
};
</script>

<style>

#emergency_support .v-expansion-panel__header{
  padding-left: 0;
  padding-right: 0;
}
</style>
