<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2019/11/groups-wanaka-roys-peak-hero-bg.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">Group Travel</h1>
                          <h3 class="white--text title mb-4">Design your perfect group package</h3>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Roys Peak Track, Wanaka</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row class="registration-main">
      <v-flex>
        <v-card flat tile>
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 pt-0 my-4>
                <v-card class="pa-0 elevation-0">
                  <v-card-text class="pa-4">
                    <span
                      class="headline font-weight-medium kiwiGreen d-block mb-3"
                    >Are you planning a Group Holiday to New Zealand?</span>
                    <v-divider class="my-4"></v-divider>
                    <p>Maybe a family, school, sports club, or even a special interest group trip? ANZCRO would love to help make this a seamless experience for you and your group, because we know that co-ordinating groups travel can be a challenging and frustrating task!</p>
                    <p>Our reservations team has years of experience in group travel arrangement, and we are more than happy to tailor an itinerary to suit your group’s individual requirements. Contact us today for more information.</p>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row class="home-reservations">
      <v-flex>
        <v-card flat tile :color="offWhite">
          <v-layout row wrap align-space-around>
            <v-flex xs12 md6 class="home-reservations-image indented">
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2019/11/groups-rafting.jpg"
                height="100%"
                position="top left"
              ></v-img>
            </v-flex>
            <v-flex xs12 md6 align-self-center class="split-content">
              <v-layout row wrap my-5>
                <v-flex xs12>
                  <h3 class="kiwiGreen title mb-4">
                    <span>If you are looking for:</span>
                  </h3>
                  <ul class="mb-4">
                    <li>Private chartered transport (large and small)</li>
                    <li>University and school ski travel</li>
                    <li>An end of season sports team trip</li>
                    <li>Conference accommodation and activities</li>
                    <li>Pre- and post-conference short-break travel arrangements</li>
                    <li>A special occasion family holiday</li>
                    <li>…plus much more!</li>
                  </ul>
                  <p class="body-1">We can help you plan your journey and provide you with an itinerary – from one day to endless days.</p>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row class="registration-main">
      <v-flex>
        <v-card flat tile>
          <v-container grid-list-xl fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 pt-0 my-4>
                <v-card class="pa-0 elevation-0">
                  <v-card-text class="pa-4">
                    <span
                      class="headline font-weight-medium kiwiGreen d-block mb-3"
                    >Group reservations</span>
                    <p>The ANZCRO Groups department is open from 9am to 5pm Mon-Fri (AEST). For the most experienced advice on group travel to Australia or New Zealand contact our dedicated Groups Team today.</p>
                    <v-layout row wrap mt-4>
                      <v-flex xs12 sm6 md4>
                        <v-card flat tile class="card-with-border text-xs-center pa-3 pa-md4 dash-rounded">
                          <h4 class="title mb-2 kiwiGreen">Telephone</h4>
                          <p class="grey--text">Call our Groups reservation line</p>
                          <v-btn large href="tel:13008855230" dark :color="headerGrey" class="px-4 elevation-0 my-1">Tel: 1300 885 523</v-btn>
                        </v-card>
                      </v-flex>
                      <v-flex xs12 sm6 md4>
                        <v-card flat tile class="card-with-border text-xs-center pa-3 pa-md4 dash-rounded">
                          <h4 class="title mb-2 kiwiGreen">Email</h4>
                          <p class="grey--text">Email our Groups reservation staff</p>
                          <v-btn large href="mailto:groups@anzcro.com.au" dark :color="headerGrey" class="px-4 elevation-0 my-1">groups@anzcro.com.au</v-btn>
                        </v-card>
                      </v-flex>
                      <v-flex xs12 sm6 md4>
                        <v-card flat tile class="card-with-border text-xs-center pa-3 pa-md4 dash-rounded">
                          <h4 class="title mb-2 kiwiGreen">Online</h4>
                          <p class="grey--text">Use our online contact form</p>
                          <v-btn large to="/contact/" dark :color="headerGrey" class="px-4 elevation-0 my-1">Contact Form</v-btn>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>


  </v-container>
</template>
<script>
export default {
  data: () => ({}),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
</style>
