<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2022/10/hero-dart-river-nz-book-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,0.65), rgba(22,22,22,.45) 15%, rgba(22,22,22,0.35) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/10/nz-book-package-collection-stacked-v2.png" alt="NZ Book 2022-24 Package Collection" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <small>The Wilderness Jet, Dart River Adventures</small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <v-layout row wrap>
                        <v-flex xs12 md10 py-0 my-0>
                          <span class="headline font-weight-medium kiwiGreen d-block mb-3">A collection of 12 packages as seen in our New Zealand Book 2022-2024</span>
                          <p>New Zealand is a truly breathtaking place where majestic fiords wait to be explored and towering mountains cloak wide open spaces of untouched wilderness. These 12 unique packages showcase the diverse range of holidays found in New Zealand from city breaks and family fun to active adventures and rail experiences including our best-selling Grand Rail Explorer package.</p>
                          <p>All packages featured can be customised, contact our expert Res Team for details.</p>
                        </v-flex>
                        <v-flex xs12 md2 py-0 my-0 text-xs-center>
                          <a target="_blank" href="https://issuu.com/holidayexperts/docs/issuu_nz_brochure_22-24?fr=sOGIwNzE0NDc5NjU"><img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/10/nz-book-cover.png" alt="NZ Book 2022-2024" class="campaign-sticker kirra-early-bird" width="124" height="174" /></a>
                          <small class="kiwiGreen d-block mt-2" style="line-height:1.2;"><a target="_blank" href="https://issuu.com/holidayexperts/docs/issuu_nz_brochure_22-24?fr=sOGIwNzE0NDc5NjU"><b>View the NZ Book 2022-24</b></a></small>
                        </v-flex>
                        <!--
                        <v-flex xs6 md2 py-0 my-0 text-xs-center>
                          <a target="_blank" href="https://cdn.anzcro.com.au/wp-content/uploads/2022/10/Kiwi-Kash-Promo-NZ-Book-2022-24-v3.pdf"><img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/10/kiwi-kash-flyer.jpg" alt="Win Kiwi Kash Flyer" class="campaign-sticker kirra-early-bird" width="124" height="174" /></a>
                          <small class="kiwiGreen d-block mt-2" style="line-height:1.2;"><a target="_blank" href="https://cdn.anzcro.com.au/wp-content/uploads/2022/10/Kiwi-Kash-Promo-NZ-Book-2022-24-v3.pdf"><b>Win up to $1,500 in prizes!</b></a></small>
                        </v-flex>
                        -->
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (AUD)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-right>
                            <span class="caption grey--text">Inclusions & Terms</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                    <b>Call ANZCRO to Book</b><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right>
                            <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
        /*
        {
          title : 'Deals',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deals-nz-book-4dra.jpg',
              name : '4 Day Rotorua Adventure',
              price : '939',
              complement : 'per person (twin share)',
              code : 'NZB224DRA',
              location : 'Rotorua (ROT)',
              valid: '01 Oct 2022 - 31 Mar 2024',
              itinerary : 'https://issuu.com/holidayexperts/docs/issuu_nz_brochure_22-24/131?fr=sOGIwNzE0NDc5NjU',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deals-nz-book-4dcoe.jpg',
              name : '4 Day Central Otago Experience',
              price : '1029',
              complement : 'per person (twin share)',
              code : 'NZB224DCOE',
              location : 'Queenstown (ZQN)',
              valid: '01 Oct 2022 - 31 Mar 2024',
              itinerary : 'https://issuu.com/holidayexperts/docs/issuu_nz_brochure_22-24/133?fr=sOGIwNzE0NDc5NjU',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deals-nz-book-5dcpwf.jpg',
              name : '5 Day City, Pools & Whales Family Holiday',
              price : '779',
              complement : 'per person (quad share)',
              code : 'NZB225DCPWFH',
              location : 'Christchurch (CHC)',
              valid: '01 Oct 2022 - 31 Mar 2024',
              itinerary : 'https://issuu.com/holidayexperts/docs/issuu_nz_brochure_22-24/132?fr=sOGIwNzE0NDc5NjU',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deals-nz-book-5dhib.jpg',
              name : '5 Day Harbour & Island Break',
              price : '945',
              complement : 'per person (twin share)',
              code : 'NZB225DHISB',
              location : 'Auckland (AKL)',
              valid: '01 Oct 2022 - 31 Mar 2024',
              itinerary : 'https://issuu.com/holidayexperts/docs/issuu_nz_brochure_22-24/131?fr=sOGIwNzE0NDc5NjU',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deals-nz-book-5dqg.jpg',
              name : '5 Day Queenstown Getaway',
              price : '1379',
              complement : 'per person (twin share)',
              code : 'NZB225DQG',
              location : 'Queenstown (ZQN)',
              valid: '01 Oct 2022 - 31 Mar 2024',
              itinerary : 'https://issuu.com/holidayexperts/docs/issuu_nz_brochure_22-24/133?fr=sOGIwNzE0NDc5NjU',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deals-nz-book-6dapt.jpg',
              name : '6 Day Alpine Pacific Triangle',
              price : '1009',
              complement : 'per person (twin share)',
              code : 'NZB226DAPT',
              location : 'Christchurch (CHC)',
              valid: '01 Oct 2022 - 31 Mar 2024',
              itinerary : 'https://issuu.com/holidayexperts/docs/issuu_nz_brochure_22-24/137?fr=sOGIwNzE0NDc5NjU',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deals-nz-book-6dcme.jpg',
              name : '6 Day City & Mountains Escape',
              price : '1655',
              complement : 'per person (twin share)',
              code : 'NZB226DCATM',
              location : 'Christchurch (CHC)',
              valid: '01 Oct 2022 - 31 Mar 2024',
              itinerary : 'https://issuu.com/holidayexperts/docs/issuu_nz_brochure_22-24/132?fr=sOGIwNzE0NDc5NjU',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deals-nz-book-7dssr.jpg',
              name : '7 Day Southern Scenic Route',
              price : '1345',
              complement : 'per person (twin share)',
              code : 'NZB227DSSR',
              location : 'Dunedin (DUD)',
              valid: '01 Oct 2022 - 31 Mar 2024',
              itinerary : 'https://issuu.com/holidayexperts/docs/issuu_nz_brochure_22-24/139?fr=sOGIwNzE0NDc5NjU',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deals-nz-book-7dmgl.jpg',
              name : '7 Day Mountains Glaciers and Lakes',
              price : '1735',
              complement : 'per person (twin share)',
              code : 'NZB227DMGL',
              location : 'Queenstown (ZQN)',
              valid: '01 Oct 2022 - 31 Mar 2024',
              itinerary : 'https://issuu.com/holidayexperts/docs/issuu_nz_brochure_22-24/138?fr=sOGIwNzE0NDc5NjU',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deals-nz-book-7dtcd.jpg',
              name : '7 Day Twin Coast Discovery',
              price : '1909',
              complement : 'per person (twin share)',
              code : 'NZB227DTCDFM',
              location : 'Auckland (AKL)',
              valid: '01 Oct 2022 - 31 Mar 2024',
              itinerary : 'https://issuu.com/holidayexperts/docs/issuu_nz_brochure_22-24/134?fr=sOGIwNzE0NDc5NjU',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deals-nz-book-8dte.jpg',
              name : '8 Day Thermal Explorer',
              price : '1569',
              complement : 'per person (twin share)',
              code : 'NZB238DTE',
              location : 'Auckland (AKL)',
              valid: '01 Oct 2022 - 31 Mar 2024',
              itinerary : 'https://issuu.com/holidayexperts/docs/issuu_nz_brochure_22-24/135?fr=sOGIwNzE0NDc5NjU',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deals-nz-book-12gre.jpg',
              name : '12 Day Grand Rail Explorer',
              price : '2699',
              complement : 'per person (twin share)',
              code : 'ANZ2312DGRE',
              location : 'Queenstown (ZQN)',
              valid: 'Apr - Nov 2023 (select start dates)',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/12/Grand-Rail-Explorer-Autumn-Spring-2023.pdf',
              call : false
            },
          ]
        },
        */
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  