<template>
  <v-container pa-0 fluid>
    <v-layout row class="registration-main">
      <v-flex>
        <v-card dark color="#181818" flat tile class="py-5">
          <v-container text-xs-center>
            <v-layout row>
              <v-flex xs12 py-5>
                <v-card class="elevation-10 py-0 mt-5">
                  <v-responsive :aspect-ratio="16/9">
                    <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/372023992?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>
                  </v-responsive>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data: () => ({
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
</style>
