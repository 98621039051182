<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2023/03/hero-bay-of-plenty-3-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.45) 15%, rgba(22,22,22,0.25) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <!--<h1 class="white--text display-1 font-weight-bold mb-3">
                              Hot Offers on<br/>Holidays at Home
                            </h1>-->
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/03/discover-the-bay-of-plenty-stacked.png" alt="Discover the Bay of Plenty" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <small>Maunganui Beach, Bay of Plenty</small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <v-layout row wrap>
                        <v-flex xs12 md12 py-0 my-0>
                          <span class="headline font-weight-medium kiwiGreen d-block mb-3"><em>Nau mai haere mai ki Te Moananui ā Toi!</em><br/><span style="color:#84c258;">Welcome to the Coastal Bay of Plenty!</span></span>
                          <p>Nestled on the east coast of New Zealand’s North Island, this region is home to idyllic, friendly towns and cities spread along 125km of pristine white sand beaches. Kayaking across a lake to see a canyon shining with glow worms, hiking through more than 56,000ha of native forest or swimming with dolphins and spotting majestic orca are among the activities on offer here. Visitors can even hop on board a boat and visit one of our two marine reserves or one of our 24 coastal islands where they’ll enjoy some of the world’s most spectacular scenery!</p>
                        </v-flex>
                        <v-flex xs12 md9 py-0 my-0>
                          <p class="subheading"><b>Claims to Fame:</b></p>
                          <ul class="mb-3">
                            <li><b>New Zealand’s best beaches:</b> Ōhope Beach and Otarawairere Bay recently took top honours in the NZ Herald’s best beach awards, and Mount Maunganui’s Main Beach is always highly rated by locals and visitors alike.</li>
                            <li><b>The Kiwi Capital of the World™:</b> Whakatāne’s spectacular conservation efforts have seen the population of kiwi soar. No other town has the elusive native bird living in such close proximity to people.</li>
                            <li><b>A thriving foodie scene:</b> Our region produces world-class kiwifruit, avocados, berries and honey, and is home to a wide array of stunning eateries.</li>
                          </ul>
                          <p>Discover a collection of holiday packages from a 2 Night luxury hideaway to a 5 Night family road trip. All packages featured can be customised by our expert Reservations Team.</p>
                      </v-flex>
                      <v-flex xs12 md3 py-0 my-0 text-xs-center>
                        <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/03/bop-logo-orange.png" alt="Bay of Plenty Logo" class="campaign-sticker mt-5" width="124" height="65" />
                      </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <!--
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 px-5 pb-5 elevation-0">
                      <v-responsive :aspect-ratio="16/9">
                        <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/548605448?h=26022f1826&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="NZ_SnowHighway_2023"></iframe></div>
                      </v-responsive>
                    </v-card>                
                </v-flex>
                -->
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (AUD)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-right>
                            <span class="caption grey--text">Agent Resources</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right text-md-center>
                          <v-tooltip top v-if="deal.sm_tile">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mr-1 px-0" color="white" :href="deal.sm_tile" target="_blank"><v-icon :color="kiwiGreen" size="22" class="">image</v-icon></v-btn>
                            </template>
                            <span>View Social Media Tile</span>
                          </v-tooltip>
                          <v-tooltip top v-if="deal.itinerary">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mx-0 px-0" :color="kiwiGreen" :href="deal.itinerary" target="_blank"><v-icon color="white" size="22" class="">description</v-icon></v-btn>
                            </template>
                            <span>View Flyer</span>
                          </v-tooltip>
                        </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
        /*
        {
          title : 'Deals',
          deals : [
            {
                thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/Coastal-Escape-SM-Tile-150x150.png',
                name : '3 Night Coastal Escape',
                price : '675',
                complement : 'per person (twin share)',
                code : 'BOP233NCE',
                location : 'Whakatane (WHK)',
                valid: '01 Apr 2023 - 31 Mar 2024',
                itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/Discover-the-Bay-of-Plenty-AUD.pdf',
                sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/Coastal-Escape-SM-Tile.png'
            },
            {
                thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/Kayak-Culture-SM-Tile-150x150.png',
                name : '3 Night Kayak & Culture',
                price : '889',
                complement : 'per person (twin share)',
                code : 'BOP233NKCE',
                location : 'Tauranga (TRG)',
                valid: '01 Apr - 30 Sep 2023',
                itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/Discover-the-Bay-of-Plenty-AUD.pdf',
                sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/Kayak-Culture-SM-Tile.png'
            },
            {
                thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/Luxury-Country-House-SM-Tile-150x150.png',
                name : '2 Night Luxury Country House',
                price : '1179',
                complement : 'per person (twin share)',
                code : 'BOP232NLCH',
                location : 'Tauranga (TRG)',
                valid: '01 Apr - 30 Sep 2023',
                itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/Discover-the-Bay-of-Plenty-AUD.pdf',
                sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/Luxury-Country-House-SM-Tile.png'
            },
            {
                thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/The-Family-of-Plenty-SM-Tile-150x150.png',
                name : '5 Day the Family of Plenty Road Trip',
                price : '3599',
                complement : 'per family (2Ad+2Ch)',
                code : 'BOP235DFAMP',
                location : 'Tauranga (TRG)',
                valid: '01 Apr - 20 Dec 2023',
                itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/Bay-of-Plenty-Family-Flyer-AUD.pdf',
                sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/The-Family-of-Plenty-SM-Tile.png'
            },
          ]
        },
        */
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  