<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2023/04/hero-norfolk-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.2) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/06/lord-howe-norfolk-island-stacked.png" alt="Paradise Found in Lord Howe Island and Norfolk Island" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <small>Norfolk Island</small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <span
                        class="headline font-weight-medium kiwiGreen d-block mb-3"
                      >Paradise found in Lord Howe & Norfolk Island.</span>
                      <p>Only a short flight from Australia’s East Coast, find paradise in Lord Howe Island and Norfolk Island.</p>
                      <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/06/thumb-lord-howe.jpg" width="140" height="140" style="float:left; margin: 0 1.5rem 1rem 0;"/>
                      <p><b>Lord Howe Island:</b> Imagine a tiny outcrop in the Pacific Ocean, covered in lush greenery, surrounded by aquamarine waters home to an untouched coral reef teeming with marine life, where only 400 visitors are allowed at any one time. It’s little wonder National Geographic named Lord Howe Island one of the world’s best destinations. Lord Howe Island offers a range of activities including Lord Howe Island Marine Park which is home to over 500 fish and coral species, spectacular hiking and walking trails, epic fishing tours, and boutique luxury lodges to simply relax and immerse yourself in Lord Howe’s vibe. For golf lovers, Lord Howe is also home to one of the most picturesque golf courses in Australia. Lord Howe Island is a short 2-hour direct flight from Sydney with QantasLink. Regular flights from Port Macquarie, Newcastle and Gold Coast are also available with Eastern Air Services.</p>
                      <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/06/thumb-norfolk.jpg" width="140" height="140" style="float:left; margin: 0 1.5rem 1rem 0;"/>
                      <p><b>Norfolk Island:</b> Around every bend you’ll find a new surprise or twist that will make you rethink your notions of paradise. The beautiful beaches are some of the Pacific’s best with clear waters sheltered by coral reefs just a short swim from the sand. Discover moody cliffs where waterfalls tumble into the sea, and witness the iconic and majestic Norfolk Island pine forests. Encounter Norfolk’s fascinating past including the first Polynesian settlers, British convicts and the Bounty mutineer descendants who shaped the Island’s culture. Experience a culinary adventure thanks to Norfolk’s organic soils and seasonal growing cycle which offers fresh paddock to plate produce, thriving farmers market, clifftop fish frys and craft-beer creations. Norfolk Island is a 2.5-hour direct flight from Brisbane or Sydney with QantasLink. Air Chatham offer direct flights from Auckland, New Zealand.</p>
                      <p><b>Discover a range of holiday packages below. All packages featured can be customised to suit your preference and flight itinerary.</b></p>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (AUD)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-right>
                            <span class="caption grey--text">Inclusions & Terms</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                    <b>Call ANZCRO to Book</b><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right>
                            <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
        {
          title : 'Deals',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/09/deal-norfolk-1.jpg',
              name : '7 Day Experience Norfolk',
              price : '999',
              complement : 'per person (twin share)',
              code : 'ANZ237DENI',
              location : 'Norfolk Island (NSW) (NLK)',
              valid: '30 Oct 2023 - 30 Apr 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/Experience-Norfolk-Island-AUD-Flyer.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/09/deal-norfolk-2.jpg',
              name : '8 Day Experience Norfolk',
              price : '1109',
              complement : 'per person (twin share)',
              code : 'ANZ238DENI',
              location : 'Norfolk Island (NSW) (NLK)',
              valid: '28 Oct 2023 - 30 Apr 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/Experience-Norfolk-Island-AUD-Flyer.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/01/deal-nisd-7.jpg',
              name : '7 Day Norfolk Island Self-Drive',
              price : '1399',
              complement : 'per person (twin share)',
              code : 'ANZ237DNISD',
              location : 'Norfolk Island (NSW) (NLK)',
              valid: '30 Oct 2023 - 30 Apr 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/Norfolk-Self-Drive-AUD-Flyer.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/01/deal-nisd-8.jpg',
              name : '8 Day Norfolk Island Self-Drive',
              price : '1699',
              complement : 'per person (twin share)',
              code : 'ANZ238DNISD',
              location : 'Norfolk Island (NSW) (NLK)',
              valid: '28 Oct - 30 Apr 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/Norfolk-Self-Drive-AUD-Flyer.pdf',
              call : false
            },
            /*
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/01/deal-lord-howe.jpg',
              name : '6 Nights Lord Howe Luxury Retreat',
              price : '4879',
              complement : 'per person (twin share)',
              code : 'ANZ236NLHL',
              location : 'Lord Howe Island',
              valid: '10 Apr 2023 - 31 Mar 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/ANZ236NLHL-Lord-Howe-Luxury-Retreat-AUD-MAR23.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/deal-lhowe.jpg',
              name : '6 Nights Luxury on Lord Howe - Air & Land',
              price : '6099',
              complement : 'per person (twin share)',
              code : 'ANZ236NLHE',
              location : 'Lord Howe Island',
              valid: '25 Aug 2023 - 31 Mar 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/Arajilla-Lord-Howe-Earlybird-Sale-Air-Land-AUD.pdf',
              call : false
            },
            */
          ]
        },
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  