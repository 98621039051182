<template>
  <v-app>
    <!-- Header -->
    <public-header @toogleMenu="toogleMenu" />
    <!-- Content -->
    <router-view />
    <!-- Footer -->
    <public-footer />
    <!-- Navigation Drawer Public -->
    <v-navigation-drawer v-model="publicDrawer" fixed right temporary>
      <public-menu @close="closeMenu" />
    </v-navigation-drawer>
    <GeoLocationDialog/>
    <PublicErrorSnackbar/>
  </v-app>
</template>
<script>
import PublicHeader from '@/components/PublicHeader';
import PublicFooter from '@/components/PublicFooter';
import PublicMenu from '@/components/PublicMenu';
import GeoLocationDialog from '@/components/public/components/GeoLocationDialog';
import PublicErrorSnackbar from "@/components/public/error/PublicErrorSnackbar";
import appService from '../../services/app.service';
export default {
  components: {
    'public-header': PublicHeader,
    'public-footer': PublicFooter,
    'public-menu': PublicMenu,
    GeoLocationDialog,
    PublicErrorSnackbar,
  },
  data() {
    return {
      publicDrawer: false,
    };
  },
  methods: {
    closeMenu() {
      this.publicDrawer = false;
    },
    toogleMenu() {
      this.publicDrawer = !this.publicDrawer;
    },
  },
  mounted() {
    appService
      .get('/session', null, true)
      // .then(data => {
      //   if (data) {
      //     this.$store.dispatch('updateUser', data);
      //   }
      // })
      .catch(err => {
        if (err) {
          //display
          this.$store.dispatch('updateUser', undefined);
        }
      });
  },
};
</script>
