<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2021/08/hero-great-journeys-mt-cook-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.55) 15%, rgba(22,22,22,0.25) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <!--<h1 class="white--text display-1 font-weight-bold mb-3">
                            Hot Offers on<br/>Holidays at Home
                          </h1>-->
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/03/new-all-time-great-deals-stacked.png" alt="All-Time Great New Zealand Holidays" class="campaign-title stacked" width="480" height="300" />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Aoraki Mt Cook, Canterbury - Photo: Miles Holden</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 py-0 my-0>
                <v-card tile class="pa-0 elevation-0 deals-content">
                  <v-card-text class="pa-5">
                    <span
                      class="headline font-weight-medium kiwiGreen d-block mb-3"
                    >A collection of our most popular New Zealand self-drive packages.</span>
                    <p>A selection of our most popular New Zealand self-drive package holidays. These holidays take in the best of the North and the South Island and are perfect for the traveller who wants to experience the highlights of New Zealand. As always, all packages can be customised. Contact us for more info.</p>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                <v-card tile dark class="pa-0 ma-0 elevation-0">
                  <v-card-text class="px-3 py-2">
                    <span
                      class="headline font-weight-medium d-block"
                    >{{section.title}}</span>
                  </v-card-text>
                </v-card>
                <v-card tile class="px-3 py-3 elevation-0">
                  <v-layout row wrap ma-0>
                    <v-flex xs12 pa-0 hidden-sm-and-down>
                      <v-layout row wrap my-0>
                        <v-flex xs12 md7 lg8>
                          <span class="caption grey--text">Package Deal</span>
                        </v-flex>
                        <v-flex xs12 md3 lg2 text-md-right>
                          <span class="caption grey--text">From Price (AUD)</span>
                        </v-flex>
                        <v-flex xs12 md2 lg2 text-md-right>
                          <span class="caption grey--text">Inclusions & Terms</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                      <v-divider></v-divider>
                      <v-layout row wrap align-center my-3>
                        <v-flex xs12 md7 lg8>
                          <v-layout row align-center>
                            <v-flex shrink hidden-md-and-down>
                              <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                            </v-flex>
                            <v-flex>
                              <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                              <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                              <span class="d-block package-data">
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Start location</span>
                                  </v-tooltip>
                                </v-chip>
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Package Code</span>
                                  </v-tooltip>
                                </v-chip>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm6 md3 lg2 text-md-right>
                          <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                          <span class="caption d-block grey--text">{{deal.complement}}</span>
                        </v-flex>
                        <v-flex xs12 sm6 md2 lg2 text-sm-right>
                          <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
      </v-flex>
    </v-layout>


  <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    copied: false,
    copiedText: '',
    sections : [
      /*
      {
        title : 'Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deal-auckland-hobbiton.jpg',
            name : '4 Nights Auckland & Hobbiton',
            price : '849',
            complement : 'per person (twin share)',
            code : 'ENT234NAHH',
            location : 'Auckland (AKL)',
            valid: '01 Apr - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/ENT234NAHH-Auckland-Hobbiton-AUD-MAR23.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deal-thumbnail-nz-city-breaks-sampler.jpg',
            name : '7 Days South Island Sampler',
            price : '1245',
            complement : 'per person (twin share)',
            code : 'ANZ2237DSIS',
            location : 'Christchurch (CHC)',
            valid: '01 Apr - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/ANZ2237DSIS-South-Island-Sampler-AUD-MAR23.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deal-gardens-of-north-island.jpg',
            name : '8 Days Gardens of North Island',
            price : '1399',
            complement : 'per person (twin share)',
            code : 'ANZ237NGONI',
            location : 'Auckland (AKL)',
            valid: '01 Sep 2023 - 31 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/Gardens-of-North-Island-AUD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deal-thumbnail-all-time-thermal.jpg',
            name : '8 Days Thermal Explorer',
            price : '1699',
            complement : 'per person (twin share)',
            code : 'NZB238DTE',
            location : 'Auckland (AKL)',
            valid: '01 Sep 2023 - 31 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/Thermal-Explorer-AUD-Flyer-1.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deal-ni-fnw.jpg',
            name : '9 Days North Island Food & Wine',
            price : '1949',
            complement : 'per person (twin share)',
            code : 'ANZ239DNIFW',
            location : 'Auckland (AKL)',
            valid: '01 Sep 2023 - 31 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/North-Island-Food-Wine-AUD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/04/deal-thumbnail-fiordland-lodges.jpg',
            name : '4 Nights Iconic Lodges of Fiordland',
            price : '2199',
            complement : 'per person (double share)',
            code : 'ANZ234NILOF',
            location : 'Queenstown Airport (ZQNA)',
            valid: '01 Apr - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/ANZ234NILOF-Iconic-Lodges-of-Fiordland-AUD-MAR23.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/04/deal-thumbnail-purepod.jpg',
            name : '4 Nights Ultimate Purepod Experience',
            price : '1989',
            complement : 'per person (twin share)',
            code : 'ANZ234NUPPE',
            location : 'Christchurch (CHC)',
            valid: '01 Aug - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/ANZ234NUPPE-Ultimate-Purepod-Experience-AUD-MAR23.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deal-13d-nd.jpg',
            name : '13 Days Northern Discovery',
            price : '2599',
            complement : 'per person (twin share)',
            code : 'ANZ2313DND',
            location : 'Auckland (AKL)',
            valid: '01 Sep 2023 - 31 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/Northern-Discovery-AUD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deal-si-leisure.jpg',
            name : '14 Day South Island at Leisure',
            price : '2789',
            complement : 'per person (twin share)',
            code : 'ANZ2314DSIAL',
            location : 'Christchurch (CHC)',
            valid: '01 Oct 2023 - 31 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/South-Island-at-Leisure-AUD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deal-thumbnail-all-time-best-of-both.jpg',
            name : '22 Days Best of Both Islands',
            price : '3999',
            complement : 'per person (twin share)',
            code : 'NZB2322BOBI',
            location : 'Auckland (AKL)',
            valid: '01 Apr - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/NZB2322BOBI-Best-of-Both-Islands-AUD-MAR23.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/09/deal-northland-4.jpg',
            name : '5 Days Northland Family Fun',
            price : '2159',
            complement : 'per family (2Ad+2Ch)',
            code : 'RTO225DNFF',
            location : 'Paihia (PIH)',
            valid: '01 Oct 2022 - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/09/ANZCRO-AUD-Northland-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/09/deal-northland-3.jpg',
            name : '3 Days Northland Luxury Retreat',
            price : '2589',
            complement : 'per person (twin share)',
            code : 'RTO223DNLR',
            location : 'Russell (RUL)',
            valid: '16 Oct 2022 - 30 Apr 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/09/ANZCRO-AUD-Northland-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/09/deal-northland-2.jpg',
            name : '7 Days Northland Twin Coast Drive',
            price : '1499',
            complement : 'per person (twin share)',
            code : 'RTO227DNTC',
            location : 'Auckland (Off-Airport) (AKLO)',
            valid: '01 Oct 2022 - 30 Apr 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/09/ANZCRO-AUD-Northland-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/09/deal-northland-1.jpg',
            name : '6 Days Northland Adventure',
            price : '899',
            complement : 'per person (twin share)',
            code : 'RTO226DNA',
            location : 'Tutukaka (NZ) (TUT)',
            valid: '01 Oct 2022 - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/09/ANZCRO-AUD-Northland-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deal-thumbnail-all-time-leisure.jpg',
            name : '14 Days South Island at Leisure',
            price : '2139',
            complement : 'per person (twin share)',
            code : 'ANZ2214DSIAL',
            location : 'Christchurch (CHC)',
            valid: '01 Oct 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/South-Island-at-Leisure-AUD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deal-thumbnail-all-time-wilderness.jpg',
            name : '11 Days NZ Wildlife Encounter',
            price : '1899',
            complement : 'per person (twin share)',
            code : 'ANZ2211DWILD',
            location : 'Dunedin (DUD)',
            valid: '01 Sep 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/NZ-Wildlife-Encounter-AUD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deal-thumbnail-all-time-taste.jpg',
            name : '9 Days A Taste of the North Island',
            price : '2215',
            complement : 'per person (twin share)',
            code : 'ANZ229DTONI',
            location : 'Auckland (AKL)',
            valid: '01 Oct 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/A-Taste-of-the-North-Island-New-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/08/flyer-thumbnail-updated-Grand-Rail-Explorer-150x150.jpg',
            name : '12 Days Grand Rail Explorer',
            price : '2499',
            complement : 'per person (twin share)',
            code : 'ANZ2112DGRE',
            location : 'Queenstown (ZQN)',
            valid: '13 Dec 2021 - 14 Mar 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/08/Grand-Rail-Explorer-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deal-thumbnail-all-time-glaciers.jpg',
            name : '6 Days City, Gardens & Glacier',
            price : '999',
            complement : 'per person (twin share)',
            code : 'ANZ226DCGG',
            location : 'Christchurch (CHC)',
            valid: '01 Oct 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/City-Gardens-Glacier-New-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deal-thumbnail-all-time-beaches.jpg',
            name : '7 Days Bays & Beaches',
            price : '1019',
            complement : 'per person (twin share)',
            code : 'ANZ227DBB',
            location : 'Auckland (AKL)',
            valid: '01 Sep 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/Bays-Beaches-New-Flyer.pdf'
          },
        ]
      },
      */
    ]
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    copyCode(event) {
      if(event){
        let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
        window.getSelection().selectAllChildren(copyCode);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyCode.innerHTML);
        this.copiedText = 'Package code copied to clipboard';
        this.copied = true;
      }
    },
    copyLocation(event) {
      if(event){
        let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
        window.getSelection().selectAllChildren(copyLocation);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyLocation.innerHTML);
        this.copiedText = 'Package start location copied to clipboard';
        this.copied = true;
      }
    }
  },
};
</script>

<style>
.gradient-bar{
  width: 100%;
  height: 9px;
  background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
  background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
}

.deals-content{
  margin-top: -60px;
}

.package-data .v-chip{
  color:#9e9e9e;
  margin-left: 0;
}

.package-data .v-chip .v-chip__content{
  padding-right: 6px;
}

.deal-thumbnail{
  width: 88px;
  height: 88px;
}

@media (max-width: 599px) {
  img.campaign-title.stacked{
    max-width: 240px;
    height: auto;;
  }
}
</style>
