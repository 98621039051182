<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/05/hero-explore-chc-2-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,.75), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.25) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <!--<h1 class="white--text display-1 font-weight-bold mb-3">
                            Hot Offers on<br/>Holidays at Home
                          </h1>-->
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/05/explore-chc-canterbury-stacked.png" alt="Explore Christchurch & Canterbury" class="campaign-title stacked" width="480" height="300" />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <!--<small>Otakaro Avon River in Autumn - Photo: Nancy Zhou</small>-->
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 py-0 my-0>
                <v-card tile class="pa-0 elevation-0 deals-content">
                  <v-card-text class="pa-5">
                    <span
                      class="headline font-weight-medium kiwiGreen d-block mb-3"
                    >Explore Christchurch & Canterbury.</span>
                    <p>Ōtautahi Christchurch is a place of perfect harmony, a refreshing urban centre full of new secrets to discover. If you haven’t been here in a while, you’re in for a surprise. The city has been through some massive changes in the past few years, and the time has come to discover it all.</p>
                    <p>The central city is filled with cutting-edge architecture alongside some of the oldest buildings in New Zealand. The Avon River intersects the city, bringing a natural landscape to the urban environment. Christchurch is the basecamp for South Island exploration, with the Banks Peninsula within reach, as well as the stunning Southern Alps and famed Canterbury Plains.</p>
                    <p><b>Discover 10 holiday packages below showcasing Christchurch & Canterbury. All packages featured can be customised by our expert Reservations Team.</b></p>
                    <!--<p><b>Learn more from The Insider:</b> <a href="/the-insider/5-reasons-to-discover-christchurch-and-the-central-south-island/">5 Reasons to Discover Christchurch and the Central South Island</a></p>-->
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                <v-card tile dark class="pa-0 ma-0 elevation-0">
                  <v-card-text class="px-3 py-2">
                    <span
                      class="headline font-weight-medium d-block"
                    >{{section.title}}</span>
                  </v-card-text>
                </v-card>
                <v-card tile class="px-3 py-3 elevation-0">
                  <v-layout row wrap ma-0>
                    <v-flex xs12 pa-0 hidden-sm-and-down>
                      <v-layout row wrap my-0>
                        <v-flex xs12 md7 lg8>
                          <span class="caption grey--text">Package Deal</span>
                        </v-flex>
                        <v-flex xs12 md3 lg2 text-md-right>
                          <span class="caption grey--text">From Price (AUD)</span>
                        </v-flex>
                        <v-flex xs12 md2 lg2 text-md-center>
                          <span class="caption grey--text">Agent Resources</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                      <v-divider></v-divider>
                      <v-layout row wrap align-center my-3>
                        <v-flex xs12 md7 lg8>
                          <v-layout row align-center>
                            <v-flex shrink hidden-md-and-down>
                              <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                            </v-flex>
                            <v-flex>
                              <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                              <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                              <span class="d-block package-data">
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Start location</span>
                                  </v-tooltip>
                                </v-chip>
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Package Code</span>
                                  </v-tooltip>
                                </v-chip>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm6 md3 lg2 text-md-right>
                          <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                          <span class="caption d-block grey--text">{{deal.complement}}</span>
                        </v-flex>
                        <v-flex xs12 sm6 md2 lg2 text-sm-right text-md-center>
                          <v-tooltip top v-if="deal.sm_tile">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mr-1 px-0" color="white" :href="deal.sm_tile" target="_blank"><v-icon :color="kiwiGreen" size="22" class="">image</v-icon></v-btn>
                            </template>
                            <span>View Social Media Tile</span>
                          </v-tooltip>
                          <v-tooltip top v-if="deal.itinerary">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mx-0 px-0" :color="kiwiGreen" :href="deal.itinerary" target="_blank"><v-icon color="white" size="22" class="">description</v-icon></v-btn>
                            </template>
                            <span>View Flyer</span>
                          </v-tooltip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
      </v-flex>
    </v-layout>


  <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    copied: false,
    copiedText: '',
    sections : [
      /*
      {
        title : 'Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/deal-chc-avon.jpg',
            name : '3 Night Christchurch City Break',
            price : '519',
            complement : 'per person (twin share)',
            code : 'ANZ234DCCB',
            location : 'Christchurch (CHC)',
            valid: '01 Apr - 31 Oct 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/Christchurch-and-Beyond-AUD.pdf',
            sm_tile : null
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/SM-CHC-FEB-23-1-150x150.jpg',
            name : '4 Night Christchurch Autumn Getaway',
            price : '619',
            complement : 'per person (twin share)',
            code : 'CHC234NCAG',
            location : 'Christchurch (CHC)',
            valid: '01 Apr - 30 Jun 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/Autumn-in-Christchurch-Flyer-AUD.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/SM-CHC-FEB-23-1.jpg'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/SM-CHC-FEB-23-2-150x150.jpg',
            name : '3 Night Luxury City & Rail',
            price : '799',
            complement : 'per person (twin share)',
            code : 'CHC233NLCR',
            location : 'Christchurch (CHC)',
            valid: '01 Apr - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/Autumn-in-Christchurch-Flyer-AUD.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/SM-CHC-FEB-23-2.jpg'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/deal-chc-city.jpg',
            name : '4 Night Christchurch City & Hot Pools',
            price : '879',
            complement : 'per person (twin share)',
            code : 'ANZ235CH',
            location : 'Christchurch (CHC)',
            valid: '01 Apr - 20 Dec 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/Christchurch-and-Beyond-AUD.pdf',
            sm_tile : null
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/deal-chc-whales.jpg',
            name : '4 Night City & Whales Getaway',
            price : '945',
            complement : 'per person (twin share)',
            code : 'ANZ235CWG',
            location : 'Christchurch (CHC)',
            valid: '01 Apr - 20 Dec 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/Christchurch-and-Beyond-AUD.pdf',
            sm_tile : null
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/SM-CHC-FEB-23-3-150x150.jpg',
            name : '5 Night Christchurch City & Wildlife',
            price : '1259',
            complement : 'per person (twin share)',
            code : 'CHC235NCCW',
            location : 'Christchurch (CHC)',
            valid: '01 Apr - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/Autumn-in-Christchurch-Flyer-AUD.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/SM-CHC-FEB-23-3.jpg'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/deal-chc-tekapo-pool.jpg',
            name : '7 Night City, Coast & Lake Escape',
            price : '1399',
            complement : 'per person (twin share)',
            code : 'ANZ237CCLE',
            location : 'Christchurch (CHC)',
            valid: '01 Apr - 31 Oct 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/Indulge-in-Christchurch-AUD.pdf',
            sm_tile : null
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/SM-CHC-FEB-23-4-150x150.jpg',
            name : '4 Night Christchurch & Akaroa Family Fun',
            price : '2269',
            complement : 'per family (2Ad+2Ch)',
            code : 'CHC234NCAF',
            location : 'Christchurch (CHC)',
            valid: '01 Apr - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/Family-Fun-in-Christchurch-Flyer-AUD-20230227.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/Christchurch-Akaroa-Family-Fun.jpg'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/SM-CHC-FEB-23-5-150x150.jpg',
            name : '4 Night Christchurch & Kaikōura Family Fun',
            price : '2459',
            complement : 'per family (2Ad+2Ch)',
            code : 'CHC234NCKF',
            location : 'Christchurch (CHC)',
            valid: '01 Apr - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/Family-Fun-in-Christchurch-Flyer-AUD.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/SM-CHC-FEB-23-5.jpg'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/deal-chc-heli.jpg',
            name : '3 Night City Break Luxury',
            price : '2785',
            complement : 'per person (twin share)',
            code : 'ANZ234DCCBL',
            location : 'Christchurch (CHC)',
            valid: '01 Apr - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/Indulge-in-Christchurch-AUD.pdf',
            sm_tile : null
          },
        ]
      },
      */
    ]
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    copyCode(event) {
      if(event){
        let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
        window.getSelection().selectAllChildren(copyCode);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyCode.innerHTML);
        this.copiedText = 'Package code copied to clipboard';
        this.copied = true;
      }
    },
    copyLocation(event) {
      if(event){
        let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
        window.getSelection().selectAllChildren(copyLocation);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyLocation.innerHTML);
        this.copiedText = 'Package start location copied to clipboard';
        this.copied = true;
      }
    }
  },
};
</script>

<style>
.gradient-bar{
  width: 100%;
  height: 9px;
  background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
  background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
}

.deals-content{
  margin-top: -60px;
}

.package-data .v-chip{
  color:#9e9e9e;
  margin-left: 0;
}

.package-data .v-chip .v-chip__content{
  padding-right: 6px;
}

.deal-thumbnail{
  width: 88px;
  height: 88px;
}

@media (max-width: 599px) {
  img.campaign-title.stacked{
    max-width: 240px;
    height: auto;;
  }
}
</style>
